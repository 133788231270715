@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .mail {
        padding: 25px 10px;
        background-clip: border-box;
        font-family: $font-title;
        font-size: 15vmin;
        line-height: 0.9em;
        word-break: keep-all;
    }

    .mailText {
        display: inline-block;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -0.15em;
            left: 0;
            width: 100%;
            height: 0.05em;
            background-color: $color-white;
        }
    }

    .mailTextBlack {
        display: inline-block;
        position: relative;
        color: $color-black;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: -0.15em;
            left: 0;
            width: 100%;
            height: 0.05em;
            background-color: $color-black;
        }
    }

    @media #{$mq-medium-screen} {

        .mail {
            padding: 50px 25px;
        }
    }

    @media #{$mq-large-screen} {
        .mail {
            font-size: 12.5vmax;
        }
    }
}
