@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

@keyframes floatX {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(3px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes floatY {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.container {
    position: relative;
    padding-top: 10px !important;

    .inner {
        width: 100%;
        height: 100%;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }
    }

    .title {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin: 0 0 35px;
        padding: 25px 0;
        transition: color 0.3s ease-out;
        color: $color-white;
        font-family: $font-title;
        font-size: 13vmin;

        svg {
            position: absolute;
            z-index: -1;
            top: -14%;
            left: 14.7%;
            width: auto;
            height: 140%;
            transition: fill 0.3s ease-out;
        }

        &:hover {
            color: $color-black;

            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }

    .link {
        display: block;
        position: relative;
        // width: fit-content;
        margin: 25px 0;
        transition: color 0.3s ease-out;
        color: $color-black;
        font-family: $font-title;
        font-size: $font-size-medium-large;
        // text-align: center;
        cursor: pointer;

        svg {
            position: absolute;
            top: -0.6em;
            left: 50%;
            transition: transform 0.3s ease-out;
        }

        &:hover {
            color: $color-white;

            em {
                text-decoration: underline;
                text-decoration-color: $color-white;
            }
        }

        &.infolettre {
            &:hover {
                color: $color-black;
            }
        }
    }

    .letterButton {
        @extend %reset-button;
        color: $color-black;
        font-family: $font-title;
        font-size: $font-size-medium-large;
        text-transform: uppercase;
    }

    .form {
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-in-out;
        font-family: $font-text;
        font-size: $font-size-normal;

        &.open {
            max-height: 250px;
        }
    }

    .projectInner {
        position: relative;
        animation: floatX 4s ease-in-out infinite;
    }

    .projectInner2 {
        position: relative;
        animation: floatY 4s ease-in-out infinite;
    }

    .link {
        .projectInner {
            animation-delay: -0.3s;
        }

        .projectInner2 {
            animation-delay: -0.6s;
        }

        &:nth-child(2) {
            .projectInner {
                animation-delay: -0.6s;
            }

            .projectInner2 {
                animation-delay: -0.8s;
            }
        }

        &:nth-child(3) {
            .projectInner {
                animation-delay: -0.9s;
            }

            .projectInner2 {
                animation-delay: -1s;
            }
        }

        &:nth-child(4) {
            .projectInner {
                animation-delay: -1.2s;
            }

            .projectInner2 {
                animation-delay: -1.2s;
            }
        }

        &:nth-child(5) {
            .projectInner {
                animation-delay: -1.5s;
            }

            .projectInner2 {
                animation-delay: -1.4s;
            }
        }

        &:nth-child(6) {
            .projectInner {
                animation-delay: -1.8s;
            }

            .projectInner2 {
                animation-delay: -1.6s;
            }
        }

        &:nth-child(7) {
            .projectInner {
                animation-delay: -2.1s;
            }

            .projectInner2 {
                animation-delay: -1.8s;
            }
        }

        &:nth-child(8) {
            .projectInner {
                animation-delay: -2.4s;
            }

            .projectInner2 {
                animation-delay: -2.1s;
            }
        }
    }

    @media #{$mq-medium-screen} {
        .inner {
            display: block;
        }

        .title {
            display: inline-block;
            position: relative;
            z-index: 1;
            padding: 25px 0;
            color: $color-white;
            font-family: $font-title;
            font-size: 13vmin;
            text-align: center;
        }

        .link {
            font-size: $font-size-very-large;
            text-align: center;
            cursor: pointer;

            svg {
                top: -0.15em;
                left: 50%;
                transition: transform 0.3s ease-out;
            }

            &:hover {
                svg {
                    transform: translateX(-54px);
                }
            }
        }

        .letterButton {
            font-size: $font-size-very-large;
        }
    }

    @media #{$mq-very-large-screen} {
        .title {
            position: absolute;
            margin-top: 15%;
            padding: 25px;
        }

        .link {
            display: inline-block;
            position: absolute;
            color: $color-black;
            font-family: $font-title;
            font-size: $font-size-very-large;
            cursor: pointer;

            svg {
                top: -0.15em;
                left: 50%;
                transition: transform 0.3s ease-out;
            }

            &.instagram {
                top: 8%;
                left: 7%;
            }

            &.linkedin {
                top: 17%;
                right: 25%;
            }

            &.blogue {
                right: 28%;
                bottom: 30%;
            }

            &.facebook {
                top: 52%;
                left: 12%;
            }

            &.contact {
                right: 10%;
                bottom: 5%;
            }

            &.infolettre {
                top: 35%;
                right: 4%;
            }

            &.facturation {
                top: 71%;
                left: 25%;
            }

            &.youtube {
                bottom: 2%;
                left: 11%;
            }

            &:hover {
                svg {
                    transform: translateX(-54px);
                }
            }
        }

        .letterButton {
            font-size: $font-size-very-large;
        }
    }
}
