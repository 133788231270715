@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    // overflow: auto;

    .inner {
        display: flex;
        position: relative;
        flex-direction: column;
    }

    .navbar {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
    }

    .translateButton {
        position: absolute;
        bottom: -40px;
        left: 0;
        width: 100%;
        color: $color-gold;
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    .sections {
        position: relative;
        flex-grow: 1;
        transition: filter;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }

    .sectionContainer {
        position: relative;
        width: 100%;
        // height: 0;
        // min-height: 0;
        // padding-bottom: 177.78%;
    }

    .background {
        @extend %fullscreen;
        overflow: hidden;
    }

    .componentWrapper {
        // @extend %fullscreen;
        height: 100%;
        overflow: hidden;
    }

    .section {
        height: auto;
        min-height: 675px;
        padding: 75px 10px;

        &.credits {
            padding: 75px 10px 0;
        }

        &.blurAnimation {
            animation: blur 5s ease-out infinite alternate;
        }

        @keyframes blur {
            0% {
                filter: blur(1px);
            }

            2% {
                filter: blur(3px);
            }

            35% {
                filter: blur(1px);
            }

            47% {
                filter: blur(1px);
            }

            56% {
                filter: blur(1px);
            }

            59% {
                filter: blur(13px);
            }

            59% {
                filter: blur(1px);
            }

            70% {
                filter: blur(1px);
            }

            88% {
                filter: blur(5px);
            }

            100% {
                filter: blur(1px);
            }
        }
    }

    .backgroundFixed {

    }

    .backgroundParallax {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media #{$mq-small-screen} {
        .sectionContainer {
            position: relative;
            width: 100%;
            height: auto;
            padding-bottom: 0;
        }

        .translateButton {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            max-width: 400px;
            margin-left: -200px;
            color: $color-gold;
            text-align: center;

            img {
                margin: 0 auto;
            }
        }
    }

    @media #{$mq-medium-screen} {
        .section {
            min-height: 100vh;
            padding: 75px 20px;

            &.credits {
                padding: 75px 20px 0;
            }
        }
    }
}
