@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .backgroundInner {
        @extend %fullscreen;
        z-index: -10;
        background-attachment: fixed;
        background-image: url('../../img/empty.png');
    }

    .trait {
        position: absolute;
        width: auto;
        height: 70%;

        svg {
            width: auto;
            height: 100%;
        }

        &.trait0 {
            top: 22%;
            left: 10%;

            svg {
                width: auto;
                height: 80%;
            }
        }

        &.trait2 {
            top: 22%;
            left: 10%;
            opacity: 0.9;

            svg {
                width: auto;
                height: 56%;
            }
        }

        &.trait4 {
            display: none;
        }

        &.trait6 {
            display: none;
        }
    }

    @media #{$mq-medium-screen} {
        .trait {
            pointer-events: none;

            &.trait0 {
                top: 25%;
                left: 25%;

                svg {
                    width: auto;
                    height: 80%;
                }
            }

            &.trait4 {
                display: initial;
                z-index: 5;
                top: 24%;
                left: 34%;
                width: 31%;
                opacity: 0.9;

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            &.trait6 {
                display: initial;
                z-index: 5;
                top: 27%;
                left: 31%;
                width: 31%;
                opacity: 0.9;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
