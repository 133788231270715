@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .inner {
        padding: 25px 0;
        font-family: $font-title;
        font-size: $font-size-medium;
        text-transform: uppercase;
        word-wrap: break-word;
    }

    .title {
        display: block;
        margin-bottom: 25px;
        color: $color-black;
    }

    .desc {
        display: block;
        color: $color-white;
    }

    @media #{$mq-medium-screen} {
        .title {
            margin-bottom: 50px;
            color: $color-black;
        }

        .inner {
            padding: 50px 0;
            font-size: 6vmin;
        }
    }
}
