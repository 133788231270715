@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .inner {
        padding-bottom: 25px;
    }

    .title {
        color: $color-white;
        font-family: $font-text;
        font-size: $font-size-medium;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .subtitle {
        margin-top: 15px;
        color: $color-gold;
        font-family: $font-title;
        font-size: $font-size-normal;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
    }

    .selectorsContainer {
        display: grid;
        width: 100%;
        padding-top: 50px;
    }

    .gridSquare {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .selector {
        position: relative;
        margin: 25px 0;
        padding: 25px;
        font-family: $font-title;
    }

    .logoContainer {
        display: flex;
        position: absolute;
        z-index: 2;
        top: 25%;
        left: 44%;
        width: 42px;
        font-size: 20px;

        img {
            display: inline-block;
            width: 58%;
            height: intrinsic;
        }
    }

    .selectorTitle {
        display: inline-block;
        margin-bottom: 0;
        padding: 10px 25px;
        background-color: $color-white;
        color: $color-black;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .resultTitle {
        @extend %description;
        position: relative;
        height: 11%;
        text-align: center;

        span {
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 100%;
        }
    }

    .resultAuthor {
        padding-top: 10px;
        opacity: 0.3;
        font-weight: 500;
        text-transform: uppercase;
    }

    .resultDescription {
        @extend %title;
        width: 100%;
        max-width: 380px;
        font-size: $font-size-small;
        font-weight: 500;

        &.poetryDesc {
            max-width: 650px;
            font-size: $font-size-normal;
            font-weight: 700;
        }
    }

    .input {
        @extend %description;
        width: 200px;
        margin: 15px 0;
        padding: 0;
        border: 0 solid;
        color: $color-black;
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .amountResult {
        @extend %description;
        position: relative;
        width: 200px;
        margin: 15px auto;
        padding: 0;
        background-color: $color-gold;
        color: $color-black;
        text-align: center;

        span {
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 100%;
            color: $color-gold;
        }
    }

    .budgetResultsContainer {
        position: relative;
        width: 100%;
    }

    .inputDollar {
        left: 100%;
        color: $color-white;
    }

    .resultPhrase {
        @extend %description;
        padding: 5px 0;
        font-size: $font-size-medium;
        text-align: center;

        &.defaultPhrase {
            position: absolute;
            top: 26%;
            width: 100%;
            padding: 0;
            transition: opacity 0.5s ease-out !important;
        }
    }

    .budgetEconomy {
        text-align: center;
    }

    .diff {
        padding: 10px 0;
        color: $color-gold;
        font-size: $font-size-medium-small;
        text-align: center;
    }

    @media #{$mq-small-screen} {
        .title {
            font-size: $font-size-large;
        }

        .subtitle {
            font-size: $font-size-normal-large;
        }

        .resultTitle {
            font-size: $font-size-medium-large;
        }
    }

    @media #{$mq-medium-screen} {
        .inner {
            padding-bottom: 50px;
        }

        .selector {
            touch-action: auto;
            margin: 0 0 25px;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: opacity 0.2s ease-out;
                border-radius: 50%;
                opacity: 0;
                background-color: #fff;
            }

            &:hover {
                &::after {
                    opacity: 0.1;
                }
            }
        }

        .title {
            font-size: $font-size-very-large;
        }

        .subtitle {
            font-size: $font-size-normal-large;
            font-weight: 700;
        }

        .selectorTitle {
            margin-bottom: 10px;
        }

        .resultTitle {
            height: 2em;
            margin-bottom: 5px;
        }

        .logoContainer {
            top: 25%;
            left: 45%;
        }

        .amountResult {
            margin: 10px auto;

            span {
                font-size: $font-size-large-small;
            }
        }
    }

    @media #{$mq-large-screen} {
        .selectorsContainer {
            display: grid;
            grid-template-columns: 2fr 3fr 2fr;
            grid-template-rows: 1fr;
            width: 100%;
            padding-top: 100px;
        }

        .gridSquare {
            padding: 25px;
        }

        .resultDescription {
            &.poetryDesc {
                font-size: $font-size-medium-small;
            }
        }
    }

    @media #{$mq-very-large-screen} {
        .resultDescription {
            font-size: $font-size-normal;

            &.poetryDesc {
                font-size: $font-size-medium-large;
            }
        }
    }
}
