@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .inner {
        width: 100%;
        font-family: $font-title;
        font-size: $font-size-medium-small;
        line-height: 1.3em;
        text-align: center;
        text-transform: uppercase;
    }

    .content {
        max-width: 100%;
    }

    .title {
        color: $color-gold;
    }

    .subtitle {
        color: $color-white;
    }

    .logos {
        display: flex;
        justify-content: center;
        width: 100%;

        svg {
            display: inline-block;
            width: auto;
            height: 100px;
            padding: 10px 10px 10px 7px;
        }

        a {
            display: inline-block;
        }

    }

    .tagline {
        opacity: 0.6;
        color: $color-white;
    }

    @media #{$mq-medium-screen} {
        .inner {
            font-size: $font-size-medium;
        }

        .content {
            max-width: 60%;
            margin: auto;
        }
    }
}
