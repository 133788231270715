/**
 * Containers
 */
%fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%container-cols {
    display: table;
    width: 100%;
}

%container-col {
    display: table-cell;
}

%page-inner {
    width: 100%;
    padding: 0 10px;

    @media #{$mq-medium-screen} {
        max-width: 960px;
        margin: 0 auto;
    }
}

/**
 * Controls
 */
%reset-button {
    display: inline-block;
    position: relative;
    padding: 0;
    border: 0;
    background: transparent;
    color: inherit;
    font-family: $font-text;
    cursor: pointer;
    appearance: none;
}

%reset-input {
    padding: 0;
    border: 0;
    background: transparent;
    font-family: $font-text;
    cursor: pointer;
    appearance: none;
}

/**
 * Styles
 */
%title {
    font-family: $font-title;
    font-size: $font-size-medium;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    @media #{$mq-small-screen} {
        font-size: $font-size-large;
    }

    @media #{$mq-medium-screen} {
        font-size: $font-size-very-large;
    }

    @media #{$mq-large-screen} {
        font-size: $font-size-very-larger;
    }
}

%title {
    font-family: $font-text;
    font-size: $font-size-medium;
    font-weight: 700;
    line-height: 1.1em;
    text-align: center;
    text-transform: uppercase;

    @media #{$mq-small-screen} {
        font-size: $font-size-large;
    }

    @media #{$mq-medium-screen} {
        font-size: $font-size-very-large;
    }

    @media #{$mq-large-screen} {
        font-size: $font-size-very-larger;
    }
}

%description {
    color: $color-gold;
    font-family: $font-text;
    font-size: $font-size-medium;
    font-weight: 700;
    line-height: 1.1em;
    text-transform: uppercase;

    @media #{$mq-small-screen} {
        font-size: $font-size-large;
    }
}
