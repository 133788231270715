@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .input {
        width: 250px;
    }

    .description {
        margin-top: 5px;
        font-size: $font-size-small;
        text-transform: uppercase;
    }

    .actions {
        margin-top: 5px;
    }

    .button {
        background-color: $color-black;
        color: $color-white;

        &:hover {
            background-color: $color-white;
            color: $color-black;
        }
    }
}
