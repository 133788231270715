@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    color: $color-gold;

    .lightbox {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
    }

    .globeButton {
        width: 40px;
        height: 40px;
        border: 0;
        background: none;
        color: inherit;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }

    .globeIcon {
        display: block;
        width: 100%;
        height: 100%;
    }

    .popup {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        max-width: 320px;
        margin-top: -4px;
        padding: 0 18px 20px;
        transition: opacity 0.2s ease;
        opacity: 0;
        background-color: #000;
        text-align: right;
        pointer-events: none;
    }

    .googleTranslate {

        margin-bottom: 10px;

        > div {
            font-size: 0;

            > span {
                display: none;
            }
        }
    }

    &.opened {
        .popup {
            opacity: 1;
            pointer-events: all;
        }
    }


    @media #{$mq-medium-screen} {
        .popup {
            max-width: 320px;
            margin-top: -20px;
            padding-right: 28px;
        }
    }
}
