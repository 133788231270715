@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    a {
        text-decoration: none;
    }

    .list {
        padding: 5px 15px;
        transition: transform 0.2s ease-out;
        background-color: $color-black;
        color: $color-white;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            transform: translateX(5px);
            color: $color-gold-dark;
        }
    }

    .one {
        background-color: $color-gold-dark;
        color: $color-white;

        &:hover {
            color: $color-black;
        }
    }

    .two {
        background-color: $color-white;
        color: $color-black;

        &:hover {
            color: $color-gold-dark;
        }
    }
}
