@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .title {
        @extend %title;
        color: $color-gold;
        font-family: $font-title;
        text-align: left;
    }

    @media #{$mq-small-screen} {
        .title {
            font-size: 41px;
        }
    }

    @media #{$mq-medium-screen} {
        .title {
            font-size: 6vmin;
        }
    }
}
