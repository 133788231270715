@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .backgroundInner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(1px);

        &.colorAnimation {
            animation-name: colorChange !important;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
            animation-iteration-count: infinite;
        }
    }

    @keyframes colorChange {
        0% {
            background-color: #ff0;
        }

        25% {
            background-color: #f0f;
        }

        50% {
            background-color: #0f0;
        }

        75% {
            background-color: #f33;
        }

        100% {
            background-color: #ff0;
        }
    }
}
