@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .input {
        @extend %reset-input;
        padding: 5px;
        background: $color-white;
    }
}
