@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .label {
        display: block;
        color: inherit;
        font-weight: 900;
        text-transform: uppercase;
    }
}
