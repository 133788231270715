@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .inner {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    .title {
        color: $color-gold;
        font-family: $font-title;
        font-size: $font-size-normal;
        text-transform: uppercase;

        &.bottom {
            text-align: right;
        }
    }

    .videoContainer {
        position: relative;
        width: 100%;
        height: 0;
        margin: 15px 0 5px;
        padding-bottom: 56.25%;
        // overflow: hidden;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .videoCredits {
        color: $color-gold;
        font-family: $font-text;
        font-size: 10px;
    }

    .button {
        @extend %reset-button;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .playLightBox {
        @extend %reset-button;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease-in;
        opacity: 1;

        &.playing {
            transition-delay: 1.5s;
            opacity: 0;
        }
    }

    .playPause {
        @extend %reset-button;
        position: absolute;
        top: 50%;
        right: 10px;
        left: 50%;
        width: 20px;
        margin-top: -10px;
        margin-left: -10px;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .slideshow {
        @extend %fullscreen;

        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;
    }

    &.showSlideshow {
        .slideshow {
            opacity: 0.5;
        }
    }

    @media #{$mq-small-screen} {
        .title {
            font-size: $font-size-medium-large;
        }
    }

    @media #{$mq-medium-screen} {
        .title {
            font-size: 68px;
        }

        .playPause {
            top: 45%;
            right: 10px;
            left: 50%;
            width: 80px;
            margin-left: -40px;
        }

        .videoCredits {
            font-size: $font-size-normal;
        }
    }
}
