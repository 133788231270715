/**
 * Colors
 */
$color-white: #fff;
$color-black: #000;
$color-gold: #b19554;
$color-gold-dark: #a6885d;
$color-pink: #ea148c;
$color-blue-light: #01c9fc;
$color-yellow: #fff02a;
$color-green: #00f015;

$color-background: $color-black;
$color-text: $color-white;

/**
 * Fonts
 */
$font-text: Arial, sans-serif;
$font-title: 'Arial Black', 'arialblack', sans-serif;

$font-size-small: 14px;
$font-size-normal: 16px;
$font-size-normal-large: 18px;
$font-size-medium-small: 20px;
$font-size-medium: 24px;
$font-size-medium-large: 30px;
$font-size-large-small: 36px;
$font-size-large: 42px;
$font-size-larger: 50px;
$font-size-very-large: 72px;
$font-size-very-larger: 94px;

/**
 * Screen size
 */
$small-screen: 500px;
$medium-screen: 790px;
$large-screen: 1000px;
$very-large-screen: 1400px;

/**
 * Media queries
 */
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-very-large-screen: 'only screen and (min-width: #{$very-large-screen})';
