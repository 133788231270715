@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .image {
        @extend %fullscreen;

        transition: opacity 0.3s ease;
        opacity: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &.active {
            opacity: 1;
        }
    }

    .photoCredits {
        margin-top: -15px;
        color: $color-white;
        font-family: $font-text;
        font-size: 14px;
        text-align: right;
        text-transform: none;
    }
}
