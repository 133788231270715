@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .lightbox {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .menuContainer {
        position: absolute;
        z-index: 2;
        top: 76px;
        left: 20px;
        transition: opacity 0.2s ease-out;
        background-color: $color-black;
    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background-color: $color-black;
    }

    .left {
        display: flex;
        position: relative;
    }

    .logoContainer {
        position: relative;

        height: 36px;
        margin-left: 20px;
        -webkit-tap-highlight-color: transparent;
    }

    .overflowDiv {
        position: relative;
        width: 40px;
        height: 100%;
        overflow: hidden;

        &.little {
            height: 120%;
            transform: skewX(-15deg);
        }

        &.animation {
            height: 120%;
            transform: skewX(-15deg);
            animation: colorRotate 2s linear infinite;
        }
    }

    @keyframes colorRotate {
        0% {
            filter: hue-rotate(0deg);
        }

        100% {
            filter: hue-rotate(360deg);
        }
    }

    .logo {
        display: inline-block;
        width: auto;
        height: 100%;
    }

    .reload {
        @extend %reset-button;
        width: 34px;
        margin: 0 20px;
        transition: opacity 0.5s ease-out;

        svg {
            display: inline-block;
            width: 100%;
            height: auto;
            animation: rotate 6s linear infinite;
        }

        &:hover {
            filter: brightness(1.4);
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .tagLine {
        display: none;
        color: $color-gold;
        font-weight: 700;
        text-transform: uppercase;
    }

    .translator {
        margin-left: auto;
    }

    .langue {
        font-family: $font-title;
        font-size: 46px;
        font-size: 46px;
        line-height: 0.8em;
        vertical-align: top;
    }

    @media #{$mq-small-screen} {
        .menuContainer {
            // top: 76px;
            // left: 76px;
        }
    }

    @media #{$mq-medium-screen} {
        .inner {
            padding: 20px;
        }

        .tagLine {
            display: block;
        }

        .overflowDiv {
            position: relative;
            width: 40px;
            height: 100%;
            overflow: hidden;
            transition: width 0.3s cubic-bezier(0, 1.04, 0.5, 1);

            &:hover {
                width: 397px;
            }
        }

        .logoContainer {
            width: 450px;
        }
    }
}
