@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .cellNumber {
        padding: 25px 10px;
        font-family: $font-title;
        font-size: 32vmin;
        text-align: center;
    }

    a {
        text-decoration: none;
    }

    .cellTrait {
        width: auto;
        height: 0.5em;
        margin-left: -25px;
    }

    @media #{$mq-medium-screen} {
        .cellNumber {
            padding: 50px 25px;
        }

        .cellTrait {
            width: auto;
            height: 0.6em;
            margin-left: -0.3em;
        }
    }
}
