@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %reset-button;
    display: inline-block;

    padding: 10px;
    background: $color-gold;
    color: $color-black;
    font-weight: 900;
    text-transform: uppercase;
}
