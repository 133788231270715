@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %reset-button;
    display: inline-block;
    height: 36px;

    &:hover {
        .layer {
            background-color: #ffd675;
        }
    }

    .layer {
        width: 30px;
        height: 6px;
        margin-top: 0;
        margin-bottom: 8px;
        transform-origin: left;
        transition: transform  0.1s ease-out, opacity  0.1s ease-out, margin-top 0.1s ease-out, background-color 0.3s ease-out;
        background-color: $color-gold;
    }

    .bottom {
        margin-bottom: 0;
    }

    &.open {
        .top {
            transform: rotate(45deg);
        }

        .middle {
            opacity: 0;
        }

        .bottom {
            margin-top: -7px;
            transform: rotate(-45deg);
        }
    }
}
