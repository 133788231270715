@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-transform: uppercase;
    }

    .teamMemberContainer {
        flex-grow: 1;
        margin-bottom: 25px;
        padding: 15px;
    }

    .title {
        padding-bottom: 15px;
        color: $color-gold;
        font-family: $font-text;
        font-size: $font-size-normal-large;

        span {
            display: block;
            margin: 10px 0;
        }
    }

    a {
        text-decoration: none;
    }

    .photoCredits {
        color: $color-gold;
        font-family: $font-text;
        font-size: 10px;
        text-transform: none;
    }

    .linkedin,
    .link {
        margin-right: 15px;
        margin-left: 0;
    }

    .linkedin {
        color: $color-white;
    }

    .link {
        padding: 5px;
        transition: background-color 0.3s ease-out;
        background-color: $color-white;
        color: $color-black;
        text-decoration: none;

        &:hover {
            background-color: $color-gold;
        }
    }

    .imageContainer {
        position: relative;
        width: 100%;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .jobDescription {
        position: absolute;
        bottom: -9%;
        left: 0;
        width: 100%;
        font-size: $font-size-medium-small;
        font-style: italic;
        pointer-events: none;
    }

    .word {
        display: block;
        width: 100%;
        padding: 0 15px;
        color: $color-gold;
        font-weight: bold;

        &:nth-child(1) {
            text-align: left;
        }

        &:nth-child(2) {
            text-align: center;
        }

        &:nth-child(3) {
            text-align: right;
        }

        &:nth-child(4) {
            text-align: right;
        }
    }

    .text {
        padding: 25px 0;
        font-family: $font-title;
        font-size: $font-size-medium;
        text-transform: uppercase;

        &.top {
            color: $color-gold;
        }
    }

    @media #{$mq-medium-screen} {
        .teamMemberContainer {
            flex-grow: 1;
            margin-bottom: 50px;
        }

        .inner {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
        }

        .title {
            font-size: $font-size-medium-small;
        }

        .text {
            font-size: $font-size-larger;
        }
    }

    @media #{$mq-large-screen} {
        .title {
            font-size: $font-size-medium-large;

            span {
                display: inline-block;
            }
        }

        .linkedin,
        .link {
            margin-right: 0;
            margin-left: 15px;
        }

        .jobDescription {
            font-size: $font-size-large;
        }
    }
}
