@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    height: 100%;

    .adresses {
        position: relative;
    }

    .inner {
        //
    }

    .title {
        @extend %title;
        color: $color-gold;
        font-family: $font-title;
        font-size: $font-size-medium-large;
        text-align: left;
    }

    .description {
        @extend %description;
        color: $color-black;
        font-family: $font-title;
    }

    .bottom {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        color: $color-black;
    }

    .image {
        position: relative;

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity 0.6s linear;

            &:nth-child(1) {
                position: relative;
            }
        }

        &.imageHoche {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .anchorOnImage {
        display: contents;
    }

    .trait {
        display: inline-block;
        visibility: hidden;
        width: 15%;
        margin-left: -3%;

        svg {
            width: 100%;
            height: auto;
        }
    }

    a {
        text-decoration: none;
    }

    .adress {
        padding: 20px 20px 0;
        color: $color-gold;
        line-height: 1.3em;
        text-transform: uppercase;

        span {
            display: block;
        }

        &.adressPistoles {
            float: left;
        }

        &.adressMoon {
            position: relative;
            margin: 0 0 25px;
        }
    }

    .adressTitle {
        color: $color-black;
        font-family: $font-title;
    }

    .adressDetails {
        position: relative;
    }

    .moon {
        position: absolute;
        bottom: -25px;
        left: -33px;
    }

    @media #{$mq-medium-screen} {
        .title {
            font-size: 6vmax;
        }

        .bottom {
            flex-direction: row;
            justify-content: center;
            padding-top: 50px;
        }

        .image {
            img {
                display: block;
                width: initial;
                max-width: 100%;
            }
        }

        .adress {
            &.adressPistoles {
                float: right;
            }

            &.adressMoon {
                position: absolute;
                right: 3%;
                bottom: 0%;
            }
        }
    }

    @media #{$mq-large-screen} {
        .image {
            &.imageHoche {
                flex-direction: row;
                align-items: flex-end;
            }
        }
    }
}
