@mixin clearfix {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
