/**
 * Title
 */
@font-face {
    font-family: 'arialblack';
    font-style: normal;
    font-weight: normal;
    font-display: swap;

    src: url('../../fonts/ArialBlack/ariblk-webfont.eot'),
        url('../../fonts/ArialBlack/ariblk-webfont.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ArialBlack/ariblk-webfont.woff2') format('woff2'),
        url('../../fonts/ArialBlack/ariblk-webfont.woff') format('woff'),
        url('../../fonts/ArialBlack/ariblk-webfont.ttf') format('truetype'),
        url('../../fonts/ArialBlack/ariblk-webfont.svg#arialblack') format('svg');

}
